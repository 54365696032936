import useSegmentAnalytics from '@/hooks/useSegmentAnalytics';

export function Button(
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  const { trackEvent } = useSegmentAnalytics();

  const getClassName = () => {
    switch (props.variant) {
      case 'light':
        return lightStyle + ' ' + props.className;
      case 'medium':
        return mediumStyle + ' ' + props.className;
      case 'alert':
        return alertStyle + ' ' + props.className;
      default:
        return defaultStyle + ' ' + props.className;
    }
  };

  const extendedOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    trackEvent('cta_tapped');

    if (props.onClick) props.onClick(e);
  };

  return (
    <button
      {...props}
      onClick={extendedOnClick}
      className={getClassName()}
      type={props.type || 'button'}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

interface ButtonProps {
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  variant?: 'light' | 'medium' | 'alert' | 'default' | undefined;
}

const baseStyle =
  'w-full flex justify-center items-center gap-3 h-auto py-3 text-[18px] rounded-full transition-all ease-in-out select-none';

const defaultStyle =
  baseStyle +
  ' ' +
  'font-light bg-[#29004C] text-[#F3F1F5] enabled:hover:bg-[#4B0082] disabled:bg-[#F3F1F5] disabled:text-[#BCBAC7] ';

const lightStyle =
  baseStyle +
  ' ' +
  ' border-[1px] lg:border-[1.5px] border-[#45007F] bg-white font-medium enabled:hover:bg-[#DCC5FF] enabled:hover:text-[#211F26]disabled:bg-[#F3F1F5] disabled:text-[#BCBAC7]';

const mediumStyle =
  baseStyle +
  ' ' +
  'text-[#29004C] bg-[#EEE2FF] disabled:bg-[#F3F1F5] disabled:text-[#BCBAC7] enabled:hover:bg-[#DCC5FF] enabled:hover:text-[#29004C] font-medium';

const alertStyle =
  baseStyle +
  ' ' +
  'text-white bg-[#E54666] disabled:bg-[#E54666] disabled:text-[#BCBAC7] enabled:hover:bg-[#CA244D] font-medium';
