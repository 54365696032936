import { usePathname, useSearchParams } from 'next/navigation';

import analytics from '@/segment';
import getFromLocalStorage from '@/utils/getFromLocalStorage';
import getPageNameFromPath from '@/utils/getPageNameFromPath';

const useSegmentAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const trackEvent = (eventName: string, eventProperties?: object) => {
    const source =
      searchParams.get('src') || getFromLocalStorage('attributionSource');
    const attributionId =
      getFromLocalStorage('attributionId') || searchParams.get('gclid');
    const impactClickId =
      searchParams.get('irclickid') || getFromLocalStorage('impactClickId');

    const defaultProperties = {
      page_name: getPageNameFromPath(pathname),
      user_source: source || '',
      attribution_id: attributionId || '',
      url: window.location.href,
    };
    const properties = { ...defaultProperties, ...eventProperties };
    let context;
    if (impactClickId) {
      context = {
        referrer: {
          type: 'impactRadius',
          id: impactClickId,
        },
      };
    }
    return analytics.track(eventName, properties, context);
  };

  const trackPageView = () => {
    analytics.page(pathname);
  };

  return { trackEvent, trackPageView };
};

export default useSegmentAnalytics;
